
import { AssetModel } from "@/models/asset";
import { Component, Vue, Prop } from "vue-property-decorator";
import { EventBus } from "@/events";
@Component
export default class AssetMoveConflict extends Vue {
  @Prop() item!: AssetModel;
  @Prop() conflictItem!: AssetModel;
  @Prop() final!: boolean;
  @Prop() id!: number;
  protected selectAction(action: string): void {
    EventBus.$emit(
      "conflictResolution",
      action,
      this.item,
      this.conflictItem,
      this.final,
      this.id
    );
    this.$emit("close");
  }

  get fileType(): string {
    let type;
    switch (this.item.file_type) {
      case "dir":
        type = "Folder";
        break;
      case "img":
        type = "Image";
        break;
      case "doc":
        type = "Document";
        break;
      default:
        type = "Asset";
    }
    return type;
  }
}
